<template>
  <body>
    <div class="print-a4-repair">
 
      <div
          style="
            width: 100%;
            height: 150px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
          "
        >
          <div style="width: 150px">
            <el-image
              fit="contain"
              style="max-width: 130px"
              v-if="
                shopInfoData.LogoFormat != null && shopInfoData.LogoFormat != ''
              "
              :src="shopInfoData.LogoFormat"
            >
            </el-image>
          </div>
          <div style="width: calc(100% - 150px)">
            <div style="width: 95%; text-align: right">
              <svg class="barcode" style="background-color: red"></svg>
            </div>
            <div style="width: 95%; text-align: right">
              <el-image
                :src="qrcodeImage"
                style="width: 90px; height: 90px; margin: 0 auto"
              >
              </el-image>
            </div>
          </div>

          <!-- <div></div>
        <div style="display: flex">
          <div>
            <div style="width: 100%; text-align: center"></div>
          </div>
        </div> -->
        </div>


      <!--店信息-->
      <h1>{{ shopInfoData.Name }}</h1>
      <p
        v-if="
          shopInfoData.ShopBossName != null && shopInfoData.ShopBossName != ''
        "
      >
        {{ shopInfoData.ShopBossName }}
      </p>

      <p v-if="shopInfoData.TaxNumber != null && shopInfoData.TaxNumber != ''">
        {{ shopInfoData.TaxNumber }}
      </p>

      <p v-if="shopInfoData.Location != null && shopInfoData.Location != ''">
        {{ shopInfoData.Location }}
      </p>
      <p>
        <span v-if="shopInfoData.ZipCode != null && shopInfoData.ZipCode != ''">
          {{ shopInfoData.ZipCode }}/
        </span>
        <span v-if="shopInfoData.City != null && shopInfoData.City != ''"
          >{{ shopInfoData.City }}
        </span>
      </p>

      <p
        v-if="
          shopInfoData.PhoneNumber != null && shopInfoData.PhoneNumber != ''
        "
      >
        {{ shopInfoData.PhoneNumber }}
      </p>
      <hr />

      <!--客户信息 Nombre Cliente-->
      <h3>{{ tools.GetPrintLanguageValue("web.顾客") }}</h3>
      <p>
        {{ tools.GetPrintLanguageValue("web.名称") }}:{{
          repairInfoData.ClientInfo.Name
        }}
      </p>
      <p
        v-if="
          repairInfoData.ClientInfo.Address === undefined ||
          repairInfoData.ClientInfo.Address === ''
        "
      >
        No hay dirección
      </p>
      <p v-if="repairInfoData.ClientInfo.Address !== undefined">
        {{ repairInfoData.ClientInfo.Address }}
      </p>
      <p
        v-if="
          repairInfoData.ClientInfo.DocNumber != null &&
          repairInfoData.ClientInfo.DocNumber != ''
        "
      >
        <span class="uppercase"
          >{{ repairInfoData.ClientInfo.DocTypeName }}
        </span>
        {{ repairInfoData.ClientInfo.DocNumber }}
      </p>
      <p
        v-if="
          repairInfoData.ClientInfo.PhoneNumber != null &&
          repairInfoData.ClientInfo.PhoneNumber != ''
        "
      >
        {{ tools.GetPrintLanguageValue("web.手机号码") }}.{{
          repairInfoData.ClientInfo.PhoneNumber
        }}
      </p>
 

      <!--维修信息-->
      <div v-for="item in repairInfoData.RepairMobileInfos" :key="item.Id + 64">
        <hr />
        <h3>{{ tools.GetPrintLanguageValue("web.预定") }}</h3>
        <div class="fl-layout">
            <div class="fl-grow">
              <p>
                {{ tools.GetPrintLanguageValue("web.维修时间") }}:
                {{ item.RepairStartTimeFormat }}
              </p>
              <p>
                {{ tools.GetPrintLanguageValue("web.品牌") }} {{
                  tools.GetPrintLanguageValue("web.型号")
                }}:{{ item.BrandAndModel }} {{ item.ModelPropertiesFormat }}
              </p>
              <p v-if="item.ImelNumber != null && item.ImelNumber != ''">
                IMEI / SN:{{ item.ImelNumber }}
              </p>
              <p v-if="item.Password != null && item.Password != ''">
                {{ tools.GetPrintLanguageValue("web.密码") }}:{{
                  item.Password
                }}
              </p>
              <p
                v-if="item.Budget > 0"
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-top: 3px;
                "
              >
                <span style="font-size: 10pt">
                  <b> {{ tools.GetPrintLanguageValue("web.预算") }}</b>
                </span>
                <span style="font-size: 11pt">
                  <b> {{ item.BudgetFormat }} </b>
                </span>
              </p>
              <p
                v-if="item.Downpayment > 0"
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-top: 3px;
                "
              >
                <span style="font-size: 10pt">
                  <b> {{ tools.GetPrintLanguageValue("web.定金") }}</b>
                </span>
                <span style="font-size: 11pt">
                  <b> {{ item.DownpaymentFormat }} </b>
                </span>
              </p>
              <p>{{ item.Comment }}</p>
            </div>
          </div>

        <hr />

        <table width="100%">
          <tr>
            <th></th>
            <th style="width: 60px">
              {{ tools.GetPrintLanguageValue("web.数量") }}
            </th>
            <th style="width: 60px">
              {{ tools.GetPrintLanguageValue("web.单价") }}
            </th>
            <th style="width: 60px">
              {{ tools.GetPrintLanguageValue("web.合计") }}
            </th>
          </tr>

          <tr v-for="(data, index) in item.RepairItems" :key="index">
            <td>
              <span class="fl-grow">{{ data.Name }}</span>
            </td>
            <td>{{ data.Number }}</td>
            <td>{{ data.Price }}</td>
            <td>{{ data.Total }}</td>
          </tr>
        </table>
      </div>

      <!-- 定金 预算-->

      <hr />

        <div class="fl-layout">
          <p class="fl-grow" style="font-size: 10pt">
            <b> {{ tools.GetPrintLanguageValue("web.合计") }} </b>
          </p>
          <p class="fl-not" style="font-size: 11pt">
            <b>
              {{ repairInfoData.TotalFormat }}
            </b>
          </p>
        </div>

      <hr />
      <p v-if="!settingModel.DotPrintDrawer">
        {{ tools.GetPrintLanguageValue("web.操作人") }}: {{ operatorName }}
      </p>
      <br />

      <div v-html="printAndTermData.ReserveTerm"></div>

      <div class="fl-layout">
        <div class="fl-grow">
          <!-- <h3>Firma del Cliente </h3> -->
          <h3>{{ tools.GetPrintLanguageValue("web.签名") }}</h3>
          <div class="print-signature-box"></div>
        </div>
        <div class="fl-grow">
          <!-- <h3>Recogido</h3> -->
          <h3>{{ tools.GetPrintLanguageValue("web.已取走") }}</h3>
          <div class="print-signature-box"></div>
        </div>
      </div>
      <br />
      <br />
      <br />

      <br />
      <div class="fl-layout">
        <div class="fl-grow">
          <!-- <h3>Firma &#x2F; Sello Establecimiento</h3> -->
          <h3>
            {{ tools.GetPrintLanguageValue("web.签名") }} &#x2F;
            {{ tools.GetPrintLanguageValue("web.盖章") }}
          </h3>

          <div class="print-signature-box"></div>
        </div>
      </div>
      <!-- <div style="width: 100%; text-align: center">
        <el-image :src="qrcodeImage" style="width: 150px; margin: 0 auto">
        </el-image>
      </div> -->
    </div>
  </body>
</template>
  <script setup>
import "../PrintCss/print.css";
import "../PrintCss/printAfour.css";
import "../PrintCss/custom-flex.css";
import tools from "../../../commons/tools.js";
import { ref, onMounted } from "vue";
import axios from "../../../commons/AxiosMethod.js";
import JsBarcode from "jsbarcode";
import { useRoute } from "vue-router";
import QRCode from "qrcode";
const route = useRoute();
const currentRepairId = ref("");
// 店铺信息
const shopInfoData = ref({
  // Name: "维修侠", // 店铺名称
  // Location: "四川成都", // 地址
  // TaxNumber NieOrCifNumber: "",
  // PhoneNumber TelephoneNo: "0811-12543574", // 联系电话
});
// 客户信息
const clientInfoData = ref({
  Name: "客户姓名",
  Address: "很长很长的地址一大串啊啊啊啊啊啊啊啊",
  DocTypeName: "DNI", // 证件类型名称
  DocNumber: "528485A", // 证件类型名称
  PhoneNumber: "0325-52143",
});
const repairItemDataInfo = ref([
  {
    Name: "设备进水", // 维修项名称
    Number: 1, // 数量
    Price: 2.0, // 单价
    TotalPrice: 2.0, // 总价
  },
  {
    Name: "更换屏幕", // 维修项名称
    Number: 1, // 数量
    Price: 4.0, // 单价
    TotalPrice: 4.0, // 总价
  },
]); // 维修的项目
const totalAllPrice = ref(6.0); // 统计的价格
// 维修单信息
const repairInfoData = ref({
  ClientInfo: {},
  // ClientId: "",
  // ImelNumber: "123545875", // IEM号
  // Password: "123456", // 密码
  // WarrantyStartTime: "", // 保修开始日期
  // BrandAndModel: "牌子/型号", // 牌子/型号
  // RepairNo: "202311052149", // 编号
  // RepairEndTime: ""// 维修结束时间
});
// 当前操作员名称
const operatorName = ref("");
// 打印设置数据
const printAndTermData = ref({
  Id: undefined,
  DotPrintDrawer: false,
  ReserveTerm: "",
  ConditionsOfRepair: "",
  WarrantyTerms: "<p>This is <strong>rich text</strong> content.</p>", // 保修条款
  Remark: "",
});

const settingModel = ref({
  DotPrintCounterfoil: localStorage.getItem("DotPrintCounterfoil") == "yes",
  DotPrintDrawer: localStorage.getItem("DotPrintDrawer") == "yes",
});

// 页面加载
onMounted(() => {
  currentRepairId.value = route.query.id;
  console.log("当前repairId" + currentRepairId.value);

  getRepairInfo(); // 维修信息
  loadPrintSetting(); // 打印条款

  loadOtherSetting();
  loadCurrentUserName();
});
const showPrintPage = () => {
  console.log("The component is now mounted.");
  setTimeout(() => {
    window.print();
    setTimeout(() => {
      console.log("fffff");
      closeCurrentPage();
    }, 500);
  }, 1500);
};


const qrcodeImage = ref("");
const initone = () => {
  if (repairInfoData.value.RepairNo) {
    const ts = parseInt(repairInfoData.value.RepairNo.valueOf(), 10);
    JsBarcode(".barcode", ts, {
      width: 2,
      height: 30,
      fontSize: 12,
    });

    
    // 获取当前网址
    var url = `${window.location.origin}/repairSchedule?repairId=${repairInfoData.value.Id}`;

    QRCode.toDataURL(url, (err, url) => {
      qrcodeImage.value = url;
    });

  }
};

// 获取打印设置
const loadPrintSetting = () => {
  axios.apiMethod(
    "/stores/Setting/GetPrintSettingByLanguageId",
    "get",
    {},
    (result) => {
      if (result.Code === 200) {
        printAndTermData.value = result.Data;
      }
    }
  );
};

// 统计总的价格
const totalPrice = () => {
  return repairItemDataInfo.value.reduce(
    (totalAllPrice, item) => totalAllPrice + item.TotalPrice,
    0
  );
};
 

// 获取维修单信息
const getRepairInfo = () => {
  var printLanguageId = localStorage.getItem("PrintLanguageId");
  axios.apiMethod(
    "/stores/Repair/GetRepairRepairReserveByIdToLanguageId",
    "get",
    {
      Id: currentRepairId.value,
      languageId: printLanguageId,
    },
    (result) => {
      console.log(result);
      repairInfoData.value = result.Data;
      initone();
    showPrintPage()
    }
  );
};

// 获取客户信息
const getClientInfo = () => {
  console.log("获取客户信息.....");
  console.log(repairInfoData.value.ClientId);
  axios.apiMethod(
    "/stores/Client/GetClientById",
    "get",
    {
      Id: repairInfoData.value.ClientId,
    },
    (result) => {
      console.log("获取客户信息end.....");
      clientInfoData.value = result.Data;
    }
  );
};
// 获取其它设置
const loadOtherSetting = () => {
  axios.apiMethod("/stores/Setting/GetStoreSettings", "get", {}, (result) => {
    if (result.Code === 200) {
      shopInfoData.value = result.Data;
      // console.log(result.Data)
    }
  });
};
// 获取当前登陆人的姓名
const loadCurrentUserName = () => {
  var json = localStorage.getItem("UserInfo");
  var userInfo = JSON.parse(json);
  operatorName.value = userInfo.Name;
 
};
const closeCurrentPage = () => {
  window.close();
};
</script>
  <style scoped>
.app_conetnt {
  box-shadow: 0 0 0px rgba(0, 0, 0, 0); /* 设置新的 box-shadow 属性 */
}
</style>
  